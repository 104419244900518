@import '../../../assets/scss/colors.scss';

.common_select_container {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .toggle_button_wrapper {
    display: flex;
    min-width: 150px;
    padding: 6px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
    min-height: 36px;
    border-radius: 7px;
    background: $component-backround;
    cursor: pointer;

    @if $dark-theme {
      border: 1px solid $border;
    }

    .toggle_button_text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.28px;
      line-height: 22px;
      user-select: none;
    }

    .selected_label {
      color: $white;
    }

    .placeholder {
      color: $placeholder;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }
}

.select_popper_menu {
  max-width: 250px;
  min-width: 160px;
  z-index: 9999;

  .menu_wrapper {
    overflow: auto;
    border-radius: 10px;
    background: $popup-background;
    box-shadow: -5px 4px 54px 0px $box-shadow;
    max-height: 240px;
    padding: 10px 0px;

    .menu_list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      align-self: stretch;
      text-wrap: wrap;
      overflow: hidden;
    }

    .menu_item {
      color: $sub-text;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.32px;
      align-self: stretch;
      white-space: break-all;
      overflow: hidden;
    }
  }
}