@import '@shared-styles/src/assets/scss/colors.scss';
@import '@shared-styles/src/assets/scss/common.scss';

.App {
  height: 100vh;
  overflow: hidden;
  // background-color: $phoenix-background;
  // color: $white;

  &:has(.staging-banner) {
    display: grid;
    grid-template-rows: min-content 1fr;
  }

  .puck-pages-wrapper {
    background-color: $white;
    color: $black;

    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
      background: $placeholder;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover,
    ::-webkit-scrollbar-thumb:hover {
      background: $sub-text;
    }
  }
}

body {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: $dark-bg-2;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: $sub-text;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $placeholder;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

button {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.text-white {
  color: $white;
}

input {
  &:focus {
    box-shadow: none !important;
  }
}

// popover arrow styles
.MuiTooltip-tooltip {
  &.MuiTooltip-tooltipArrow {
    span::before {
      border: 1px solid #b0bce133;
    }
  }
}

// Table styles
.Common_Table {
  height: 100%;
  border-radius: 10px;
  background: $dark-bg-2;
  border: 1px solid rgba(163, 174, 208, 0.2);
  overflow: auto;
  position: relative;
  height: 100%;
  box-shadow: 0 10px 60px $box-shadow;

  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          height: auto;
          padding: 6px 12px;
          font-size: 13px;
          font-weight: 500;
          color: $sub-text;
          white-space: nowrap;
          border: none;
          border-bottom: 1px solid $table-border;
          background-color: $dark-bg;

          .Mui-active {
            color: inherit;
          }

          svg {
            color: inherit;
          }

          .MuiCheckbox-root {
            padding: 0;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding: 10px 12px;
          font-size: 14px;
          height: auto;
          white-space: nowrap;
          border-bottom: 1px solid $table-border;
          max-width: 500px;
          color: $white;
          background-color: $dark-bg-2;

          .card-brand {
            display: flex;
            min-width: 29px;
            max-width: 52px;
            min-height: 25px;
            padding: 5px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: $white;
            @if $dark-theme {
              background: $alwaysWhite;
              border: 1px solid $border;
            }
          }

          .link_btn {
            cursor: pointer;
            color: $sec-ui;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            &:hover {
              text-decoration-line: underline;
            }
          }

          .expand-row {
            padding: 0;
          }

          .traffic_uppercase {
            text-transform: uppercase;
          }

          .table-icons {
            width: 20px;
            height: 20px;
          }

          .center-align {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          .MuiCheckbox-root,
          .tags_wrapper {
            padding: 0;
          }

          .MuiTable-root {
            .table_header {
              .MuiTableRow-head {
                .MuiTableCell-stickyHeader {
                  height: auto;
                  padding: 6px 12px;
                  font-size: 13px;
                  font-weight: 500;
                  white-space: nowrap;
                  background-color: $dark-bg !important;
                }
              }
            }

            .table_body {
              background-color: $nested-table-bg;

              .table_row {
                .table_cell {
                  padding: 12px;
                  font-size: 14px;
                  height: auto;
                  white-space: nowrap;
                  border-bottom: 1px solid $table-border;

                  .reason-column {
                    max-width: 400px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    white-space: break-spaces;
                    word-break: break-word;
                  }
                }
              }
            }
          }
        }
        &:hover:not(.row_collapsed) {
          background-color: transparent;
          .MuiTableCell-root {
            background-image: linear-gradient($table-hover, $table-hover);
          }
        }
        &.Mui-selected:not(.row_collapsed) {
          background-color: transparent;
          .MuiTableCell-root {
            background-image: linear-gradient($table-active, $table-active);
          }
        }
      }
    }
    .MuiTableFooter-root {
      background-color: $component-backround;
      position: sticky;
      bottom: 0;
      left: 0;
      .MuiTableRow-root {
        .MuiTableCell-root {
          border: none;
          padding: 10px 12px;
          font-size: 14px;
          height: auto;
          white-space: nowrap;
          .sticky-button {
            position: sticky;
            left: 50%;
            transform: translateX(-50%);
            color: $sec-ui;
          }
        }
      }
    }
  }

  &.table-center-align {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            text-align: center;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            text-align: center;
          }
        }
      }
    }
  }

  &.table-bordered {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            border: 1px solid $table-border;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            border: 1px solid $table-border;
          }
        }
      }
    }
  }
}

// No record
.no-data-row {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0%);
  width: fit-content;
  color: $placeholder;
}

//Side filters
.side_filter_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 40px;
  width: 100%;
  align-items: center;
  gap: 10px;

  .transaction_side_filtericon {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    aspect-ratio: 1;

    &.col_filter {
      svg {
        margin: 2px -2px -2px 2px;
      }
    }

    &.margin_filter {
      svg {
        margin: 1px 0 -1px 0;
      }
    }

    &.active {
      background: $sub-text;

      svg {
        path {
          fill: $phoenix-background;
          stroke: $phoenix-background;
        }
      }

      &.margin_filter {
        svg {
          path {
            stroke: $phoenix-background;
            fill: $sub-text;
          }
        }
      }
    }
  }

  .filter_selected_wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: left;
    width: 290px;
    position: fixed;
    background-color: $dark-bg-2;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 10;
    box-shadow: -10px 0 20px $box-shadow;

    .filter_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;

      .filter_section_Header {
        font-size: 18px;
        font-weight: 500;
      }

      .close_ison {
        cursor: pointer;
      }
    }

    .filter_container {
      overflow: auto;
    }
  }
}

.tooltip-container {
  border-radius: 8px;
  pointer-events: none;
  color: $white;
  background-color: $dark-bg-2;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all 0.25s ease;
  opacity: 1;
  padding: 0 !important;
  border: 1px solid $table-border;
  box-shadow: 0 4px 16px $box-shadow;

  .tooltip-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px;
    font-size: 14px;
    white-space: nowrap;
    position: relative;
    margin-top: -1px;
    font-weight: 500;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: $table-border;
      inset: auto 10px 0 10px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    :not(.tooltip-data) {
      color: $white;
    }

    .tooltip-data {
      font-weight: 600;

      &.active {
        background: linear-gradient(
          100deg,
          #f0b351 0%,
          #f0b351 10.43%,
          #ff5acd 100%,
          #6750a4 100%
        );
        background: linear-gradient(
          100deg,
          #f0b351 0%,
          #f0b351 10.43%,
          #ff5acd 100%,
          #6750a4 100%
        );
        background-clip: text;
        color: transparent;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 50%;
    background: var(--bg-color, $dark-bg-2);
    border-top: 1px solid $table-border;
    border-left: 1px solid $table-border;
    width: 10px;
    height: 10px;
    border-radius: 3px 0 0 0;
    transition: all 0.25s ease;
  }

  &.lineChart {
    border: none;
  }

  &.pointer-left {
    &::before {
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &.lineChart {
      box-shadow: none;
      border: none;
      background-color: transparent;

      &::before {
        display: none;
      }

      -webkit-box-reflect: left -2px conic-gradient(from -90deg at 20% 50%, rgb(
              0,
              0,
              0
            )
            10%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgb(0, 0, 0) 90%);
      -webkit-box-reflect: left -2px conic-gradient(from -90deg at 20% 50%, rgb(
              0,
              0,
              0
            )
            10%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgb(0, 0, 0) 90%);
    }
  }

  &.pointer-right {
    &::before {
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%) rotate(135deg);
    }

    &.lineChart {
      box-shadow: none;
      border: none;
      background-color: transparent;

      &::before {
        display: none;
      }

      -webkit-box-reflect: right -2px conic-gradient(from 90deg at 80% 50%, rgba(
              0,
              0,
              0,
              1
            )
            10%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 1)
            90%);
      -webkit-box-reflect: right -2px conic-gradient(from 90deg at 80% 50%, rgba(
              0,
              0,
              0,
              1
            )
            10%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 1)
            90%);
    }
  }

  &.barChart {
    &::before {
      top: 20px;
    }
  }

  &.pointer-bottom {
    &::before {
      top: auto;
      bottom: -10px;
      transform: translate(-50%, -50%) rotate(225deg);
    }

    &.barChart {
      &::before {
        top: auto;
        bottom: 10px;
      }

      &.pointer-right {
        &::before {
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }

      &.pointer-left {
        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}

.card {
  border: 1px solid $table-border;
  border-radius: 12px;
  background-color: $dark-bg-2;
}

.MuiBox-root {
  border-color: $border !important;
}
