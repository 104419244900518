.Columns {
  display: flex;
  grid-template-columns: repeat(12, 1fr);
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
  width: 100%;
  // height: 100%;
}

@media (min-width: 764px) {
  .Columns {
    display: grid;
  }
}
